import React from "react"
import Slugger from "github-slugger"
import styles from "./toc.module.css"

const slugger = new Slugger()
const classForDepth = depth => [
  styles.tocItem0,
  styles.tocItem1,
  styles.tocItem2,
  styles.tocItem3,
  styles.tocItem4,
  styles.tocItem5,
  styles.tocItem6,
][depth]


const displayToc = headings => <ul className={styles.toc}>
  {headings.map(heading => (
    <li key={heading.value} className={classForDepth(heading.depth)}>
      <a href={`#${slugger.slug(heading.value)}`}>
        {heading.value}
      </a>
    </li>
  ))}
</ul>

const TOC = ({ headings }) => {
  const [showToc, setShowToc] = React.useState(false)
  return (
    <div className={styles.tocBox}>
      <div
        role="presentation"
        className={styles.tocHeader}
        onClick={() => setShowToc(!showToc)}
      >
        Table of Contents
      </div>
      {showToc && displayToc(headings)}
    </div>
  )
}

export default TOC
